<template>
  <section class="clients" style="width: 100%">
    <router-view />
  </section>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "FollowUps",
});
</script>

<style scoped></style>
